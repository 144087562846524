import eye from "../../../../assets/eye.svg";
import eyeOff from "../../../../assets/eye-off.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLabelOfProperties,
  disableSpecificShipsfromOnFirstStep,
  disableVariant,
} from "../../../../redux/slices/product-variants-slice";
import { useEffect, useState } from "react";
import { Input ,Tooltip} from "antd";
const VariantsPropertiesContainer = ({
  value,
  property,
  disable,
  shipsFrom,
}) => {
  const dispatch = useDispatch();
  const { productData, shipsFromArray } = useSelector(
    (store) => store.productVariants
  );
  const { data } = productData;
  const { product_variants } = data;
  // const [disable, setDisable] = useState(false)
  const [changeInStatus, setChangeInStatus] = useState(false);
  // check its disable or enable
  // useEffect(() => {
  //     product_variants.forEach(item => {
  //         item.variant_properties.forEach(prop => {
  //             if (prop.shopify_property_label === property && prop.shopify_property_value === value) {
  //                 setDisable(item.is_disabled);
  //             }
  //         });
  //     });
  // }, [changeInStatus])

  // Find the variant that matches the given property and value

  const handlDisable = (is_disabled) => {
    console.log("is disbaled true => ", is_disabled);
    if (shipsFrom) {
      dispatch(
        disableSpecificShipsfromOnFirstStep({
          property_name: property,
          property_value: value,
          shipsFrom,
          is_disabled: is_disabled,
        })
      );
    } else {
      dispatch(disableVariant({ value, property, is_disabled }));
    }
    setChangeInStatus(!changeInStatus);
  };

  return (
    <div
      className={`${
        disable && "border-[#cad1d7]"
      } variants-edit-control-container`}
    >
      <Input
        value={value}
        disabled={disable}
        onChange={(e) =>
          dispatch(
            changeLabelOfProperties({
              property,
              propertyValue: value,
              value: e.target.value,
            })
          )
        }
        className={`${
          disable && "text-[#a5afbb] custom-disabled-input"
        } disabled:bg-none   !shadow-none !border-0 focus:!border-0 focus:!outline-0`}
      />

      <span className={`${disable && "bg-[#cad1d7]"} eye-span`}></span>
      <Tooltip  title={ shipsFromArray?.length > 0 && !shipsFrom ? 'Please select the origin country (Ships from) in Step 2 Variant Pricing' : ''} overlayClassName="custom-tooltip-variant-propertry">
      {disable ? (
       <img
       style={{
         cursor: shipsFromArray?.length > 0 && !shipsFrom ? 'not-allowed' : 'pointer',
       }}
       onClick={() =>
         shipsFromArray?.length > 0 && !shipsFrom
           ? null
           : handlDisable(false)
       }
       src={eyeOff}
       alt=""
     />
      ) : (
        <img
        style={{
          cursor: shipsFromArray?.length > 0 && !shipsFrom ? 'not-allowed' : 'pointer',
        }}
          onClick={() =>
            shipsFromArray?.length > 0 && !shipsFrom ? null : handlDisable(true)
          }
          src={eye}
          alt=""
        />
      )}
</Tooltip>
     
    </div>
  );
};
export default VariantsPropertiesContainer;
