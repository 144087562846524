import React, { useEffect, useState } from "react";
import trashbin from "../../../../assets/trashbin.svg";
import editpencil from "../../../../assets/editpencil.svg";
import cameraIcon from "../../../../assets/cameraIcon.svg";

import ImagesGalleryModal from "../../RightSection/ImagesGalleryModal";

import "./VariantsImageGallery.css";
import VariantsPropertiesContainer from "../VariantsProptiesContainer/VariantsProptiesContainer";
import { useDispatch, useSelector } from "react-redux";
import checkVariantDisable from "../../../../Utilities/productVariantsHandlers/checkVariantDisable";
import { changeSelectedVariantImage, setChangeVariantImageKey, setToUpdateImageDetails, updateVariantImageFromCanvaEdit } from "../../../../redux/slices/product-variants-slice";
import checkDisableShipsFromOnEditView from "../../../../Utilities/productVariantsHandlers/checkDisableShipsFromOnEditView";
import { callBackendAPI } from "../../../../helpers/apiCalls";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LazyBackgroundImg from "../../../LazyBackgroundImg";
import getUpdatedArraysAfterCanvaEditing from "../../../../Utilities/getUpdatedArraysAfterCanvaEditing";
import canvaIcon from '../../../../assets/canva.svg'
import syncWithCanva from '../../../../assets/sync-edits-canva.svg'
const VariantsImageGallery = ({
  galleryImages,
  setGalleryImages,
  sectionsImages,
  setSectionsImages,
  property,
  productImages,
  setProductImages,
  aliexpressImages,
  toIterate,
  shipsFrom,
  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  toUpdateImageDetails
}) => {
  const { productData, globallyDisabledProperties, shipsFromArray } =
    useSelector((store) => store.productVariants);
  const { data } = productData;
  const { product_variants } = data;
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const [editImageIndex, setEditImageIndex] = useState();
  const [multipleFlag, setMultipleFlag] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered index
  const { mediaImages, changeVariantImageKey } = useSelector(
    (store) => store?.productVariants
  );
  const dispatch = useDispatch();

  const handleCanva = (imageUrl, col) => {
    if (isEditCanvaLoading) return;
    else if (awaitingRefresh) {
      setIsEditCanvaLoading(true);

      callBackendAPI("canva/design", "GET", {
        designId: awaitingRefresh.designId,
        update: true,
      })
        .then((response) => {
          if (response.status === "success") {
            if (response.accessToken) {
              localStorage.setItem("canva_access_token", response.accessToken);
            }
            dispatch(changeSelectedVariantImage(response?.url));

            console.log("<= Canva return success varinats iamge =>");
            console.log("canva/design", response);
            console.log("awaitingRefresh", awaitingRefresh);
            const { updatedProductImages, updatedSectionsImages } = getUpdatedArraysAfterCanvaEditing({
              productImages: galleryImages,
              sectionsImages, imageUrl: toUpdateImageDetails?.imageUrl,
              updatedUrl: response?.url
            });
            setGalleryImages(updatedProductImages);
            setSectionsImages(updatedSectionsImages);
            dispatch(updateVariantImageFromCanvaEdit({ existingUrl: toUpdateImageDetails?.imageUrl, newUrl: response?.url }))
          }
          setAwaitingRefresh(false);
        })
        .finally(() => {
          setIsEditCanvaLoading(false);
        });

      return;
    }

    if (isCanvaConnected) {
      setIsEditCanvaLoading(true);

      const image = new Image();

      image.onload = function () {
        callBackendAPI("canva/edit", "GET", {
          imageUrl,
          width: image.width,
          height: image.height,
        })
          .then((response) => {
            if (response.status === "success") {
              dispatch(setToUpdateImageDetails({
                index: 0,
                to: '',
                key: '',
                imageUrl
              }))
              // dispatch(setChangeVariantImageKey(col));

              if (response.accessToken) {
                localStorage.setItem(
                  "canva_access_token",
                  response.accessToken
                );
              }

              setAwaitingRefresh({
                designId: response.designId,
                imageUrl,
              });

              console.log(' variant images correlation state:', imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./)?.[1] ||
                imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]);

              window.open(
                `${response.editUrl}&correlation_state=${imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./)?.[1] ||
                imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
                }`,
                "_blank"
              );

            } else {
              alert("Please reconnect to Canva");
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Please reconnect to Canva");
          })
          .finally(() => {
            setIsEditCanvaLoading(false);
          });
      };

      image.src = imageUrl;
    } else {
      alert("Please connect to Canva first");
    }
  };

  const handleImageEdit = (index, col) => {
    if (isEditCanvaLoading) return;
    console.log(col);
    dispatch(setChangeVariantImageKey(col));
    setEditImageIndex(index);
    setIsImagesModalVisible(true);
  };

  const handleImageDelete = (indexToRemove) => {
    if (isEditCanvaLoading) return;

    setProductImages((prevArray) =>
      prevArray.filter((item, index) => index !== indexToRemove)
    );
  };
  const handleAddMediaClick = () => {
    if (isEditCanvaLoading) return;

    setMultipleFlag(true);
    setIsImagesModalVisible(true);
  };
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  // const handleEyeClick = () => { };
  return (

    <div className=" grid grid-cols-2 xl:grid-cols-3 gap-2 ">
      {toIterate[property]?.map((value, index) => {
        let disable;
        if (shipsFromArray.length > 0) {
          disable = checkDisableShipsFromOnEditView({
            product_variants,
            property_name: property,
            property_value: value,
            globallyDisabledProperties,
            shipsFrom: shipsFrom,
          });
        } else {
          disable =
            globallyDisabledProperties[property]?.includes(value) ?? false;
        }
        // let disable = checkVariantDisable(product_variants, property, value)
        return (
          <div 
          key={index}
          
          className="flex flex-col mb-[32px] ">

            <div
              className="mb-[8px] aspect-[1/1] "
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <LazyBackgroundImg
                img={productImages[value] ? productImages[value] : ''}
                className={`gallery-image-edit-container-variants ${disable && "opacity-[0.5] hover:cursor-not-allowed"
                  }`}>


                {/* <LazyLoad  > */}
                {/* <img
                  className="sku-img-styles !h-[118px]"
                  src={productImages[value] ? productImages[value] : ""}
                  alt=""
                /> */}

                {/* </LazyLoad> */}

                {disable
                  ? null
                  : hoveredIndex === index && (
                    <div className="background-gallery-image-overlay-edit-image-section">
                      <div className="edit-image-upper-portion !justify-between flex-col h-[100%]  gap-[5px]">


                        <button
                          className="gallery-image-edit-button  !rounded-[4px]"
                          onClick={() => handleImageEdit(index, value)}
                        >
                          <img src={editpencil} alt="edit" />
                        </button>
                        {!!isCanvaConnected && (productImages[value].includes("sofia-app.io") || productImages[value].includes("alicdn.com")) && (

                          // {!!isCanvaConnected &&
                          //   productImages[value].indexOf("alicdn.com") > -1 && (

                          <button
                            className="gallery-image-edit-button bg-white !w-auto  !py-0 !pl-0 !pr-2 !m-0 !justify-start hover:!bg-[#F4F6F7]"
                            onClick={() => handleCanva(productImages[value], value)}
                          >
                            <img className="m-[8px]" width='125px' src={canvaIcon} alt="Sync Canva edits" />
                            {
                              awaitingRefresh ?
                                <p style={{ lineHeight: 1.2 }} className="xl:text-[6px] xl:text-[9px] variant-canva-text-sync font-semibold">

                                  Sync Canva edits

                                </p> :
                                <p style={{ lineHeight: 1.2 }} className="  xl:text-[8px] 2xl:text-[12px] variant-canva-text-edit  font-semibold">

                                  Edit in Canva

                                </p>
                            }
                          </button>
                        )}
                      </div>
                    </div>
                  )}
              </LazyBackgroundImg>

            </div>
            <VariantsPropertiesContainer
              shipsFrom={shipsFrom}
              property={property}
              value={value}
              disable={disable}
            />
          </div>
        );
      })}


      <ImagesGalleryModal
        aliexpressImages={aliexpressImages}
        multipleFlag={multipleFlag}
        openModal={isImagesModalVisible}
        productImages={productImages}
        setProductImages={setProductImages}
        setIsImagesModalVisible={setIsImagesModalVisible}
        imageindex={editImageIndex}
        setMultipleFlag={setMultipleFlag}
        productImageFlag={true}
      />
    </div>

  );
};

export default VariantsImageGallery;
