import connectCanvaIcon from '../../assets/connect-canva.svg'
import canvaIcon from '../../assets/canva.svg'
import { useSelector } from 'react-redux'
const ConnectCanva = ({ handleCanva, isCanvaConnected }) => {
  const { email } = useSelector(store => store.authUser)
  console.log('connect canva email verify=>', email);

  return email === 'sofia-test@pokemail.net' && (
 
    <button
      className="gallery-image-edit-button bg-white !w-auto !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7] "
      onClick={
        handleCanva
      }
    >
      <img className="m-[8px]" width='125px' src={canvaIcon} alt="Sync Canva edits" />
      {
        !isCanvaConnected ?
          <p className="text-[13px] font-semibold">

            Connect to Canva

          </p> :
          <p className="text-[13px] font-semibold">

            Disconnect from Canva

          </p>
      }
    </button>
    //     }
    // </button>
  )
}
export default ConnectCanva