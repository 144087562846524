import { useState } from "react";
import { Input, Segmented, Spin } from "antd";
import VariantsImageGallery from "./VariantsImageGallery/VariantsImageGallery";
import VariantsPropertiesContainer from "./VariantsProptiesContainer/VariantsProptiesContainer";
import useVariantsData from "../../hooks/useVariantsData";
import {
  changeLabelNameOfProperties,
  setEditViewStyle,
} from "../../../redux/slices/product-variants-slice";
import { useDispatch, useSelector } from "react-redux";
import checkDisableShipsFromOnEditView from "../../../Utilities/productVariantsHandlers/checkDisableShipsFromOnEditView";
import ConnectCanva from "../../Canva/ConvaConnect";

const ProductVariantsComponent = ({
  shipsFrom,
  isCanvaConnected,
  handleCanva,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  productImages,
  setProductImages,
  sectionsImages,
  setSectionsImages,
  toUpdateImageDetails
}) => {
  const dispatch = useDispatch();
  const {
    productData,
    globallyDisabledProperties,
    totalEnabledVariants,
    editViewStyle
  } = useSelector((store) => store.productVariants);
  const { data } = productData;
  const { product_variants } = data;
  const {
    propertiesArray,
    uniqueValuesObject,
    formattedImagesObject,
    newImagesObject,
    shipsFromArray,
  } = useVariantsData(productData);
  const [variantImages, setVariantImages] = useState(data.aliexpress_images);

  return (
    <div className="right-variant-container">
      <div className="variant-header-container">
        <h6 className="variant-header">Variants ({totalEnabledVariants})</h6>
        <ConnectCanva handleCanva={handleCanva} isCanvaConnected={isCanvaConnected} />

      </div>
      <div>
        <h6 className="variant-view-text">Variant Display Style</h6>
        <Segmented
          style={{ width: "100%" }}
          className="variant-view-styles"
          defaultValue={editViewStyle}
          onChange={(value) => dispatch(setEditViewStyle(value))}
          options={["Dropdown", "Pills"]}
        />
      </div>
      {propertiesArray?.map((property, index) => (
        <>
          {uniqueValuesObject[property]?.length > 1 ? (
            <div key={index}>
              <h6 className="variant-view-text">Variant : {property}</h6>

              <Input
                value={property}
                onChange={(e) =>
                  dispatch(
                    changeLabelNameOfProperties({
                      property,
                      value: e.target.value,
                    })
                  )
                }
                className={`mb-[16px] variant-name-container  `}
              />

              {newImagesObject.hasOwnProperty(property) ? (
                <Spin spinning={isEditCanvaLoading}>
                  <VariantsImageGallery
                    toUpdateImageDetails={toUpdateImageDetails}
                    galleryImages={productImages}
                    setGalleryImages={setProductImages}
                    sectionsImages={sectionsImages}
                    setSectionsImages={setSectionsImages}
                    shipsFrom={shipsFrom}
                    toIterate={uniqueValuesObject}
                    property={property}
                    productImages={newImagesObject[property]}
                    productVariantDetails={formattedImagesObject}
                    setProductImages={setVariantImages}
                    aliexpressImages={variantImages}
                    isCanvaConnected={isCanvaConnected}
                    awaitingRefresh={awaitingRefresh}
                    setAwaitingRefresh={setAwaitingRefresh}
                    isEditCanvaLoading={isEditCanvaLoading}
                    setIsEditCanvaLoading={setIsEditCanvaLoading}
                  />
                </Spin>
                
              ) : (
                <div className="grid grid-cols-3 xl:grid-cols-4 gap-2 mb-[32px]">
                  {uniqueValuesObject[property]?.map((val, i) => {
                    let disable;
                    if (shipsFromArray.length > 0) {
                      disable = checkDisableShipsFromOnEditView({
                        product_variants,
                        property_name: property,
                        property_value: val,
                        globallyDisabledProperties,
                        shipsFrom: shipsFrom,
                      });

                      console.log(
                        "whole saler is ships array not 0",
                        shipsFrom,
                        property,
                        val,
                        "=>>>",
                        disable
                      );
                    } else {
                      disable =
                        globallyDisabledProperties[property]?.includes(val) ??
                        false;
                    }
                    return (
                      <VariantsPropertiesContainer
                        shipsFrom={shipsFrom}
                        key={i}
                        property={property}
                        value={val}
                        disable={disable}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          ) : null}
        </>
      ))}
    </div>
  );
};

export default ProductVariantsComponent;
